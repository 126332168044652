import { Loader } from '../Widgets';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Employee } from 'service/Models';
import ServerGateway from 'service/ServerGateway';
import Webcam from "react-webcam";
import toast, { Toaster } from 'react-hot-toast';

export const PunchAttendance = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [employee, setEmployee] = useState<Employee>();
    const [locations, setLocations] = useState<any[]>([]);
    const [gatepasses, setGatepasses] = useState<any[]>([]);
    const [location, setLocation] = useState<any>();
    const [currentCoordinate, setCurrentCoordinate] = useState<any>();
    const [distance, setDistance] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGatepassSaving, setIsGatepassSaving] = useState<boolean>(false);
    const [captureVideo, setCaptureVideo] = useState(false);

    const webcamRef = useRef(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    let geoLocationOptions = {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0,
    };

    useEffect(() => {
        if (user.profile) {
            setEmployee(user.profile.profile);
            getGatepasses();
        }
    }, [user]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setCurrentCoordinate(position.coords);
        });

        navigator.geolocation.watchPosition(onChangePosition, null, geoLocationOptions);
    }, []);

    const onChangePosition = (position) => {
        setCurrentCoordinate(position.coords);
    }

    useEffect(() => {
        if (location && currentCoordinate) {
            let distance = calculateDistance(location.lattitude, location.longitude, currentCoordinate.latitude, currentCoordinate.longitude).toFixed(2);
            setDistance(distance);
        }
    }, [location, currentCoordinate]);

    const handleVideoOnPlay = () => {
        setCaptureVideo(true);
    }

    const getGatepasses = () => {
        ServerGateway
            .getGatepass(employee?.id, "0")
            .then((data) => {
                setGatepasses(data.entries);
            })
            .catch(error => {
                console.log(error);
            });
    }

    function calculateDistance(lat1, lon1, lat2, lon2) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            return dist * 1000;
        }
    }

    const punchAttendance = () => {
        if (gatepasses.length > 0) {
            let lastGatepassTime = moment(gatepasses[0].issuedAt);
            let currentTime = moment();
            let diff = currentTime.diff(lastGatepassTime, 'minutes');
            if (diff < 5) {
                toast('Please wait for 5 minute before punching again', { position: 'bottom-center', className: 'btn bg-danger' });
                return;
            }
        }

        let remarks: any = {};
        if (locations && locations.length > 0) {
            remarks = {
                lat: currentCoordinate.latitude,
                long: currentCoordinate.longitude,
                location: location.name,
                distance: distance + "m"
            };
        }
        else {
            remarks = {
                lat: currentCoordinate.latitude,
                long: currentCoordinate.longitude
            };
        }
        const imageSrc = (webcamRef.current as any).getScreenshot();
        remarks.imageSrc = imageSrc;

        let gatepass = {
            gatepassType: 'App',
            employeeId: employee?.id,
            issuedAt: moment().format("YYYY-MM-DDTHH:mm:ss"),
            remarks: JSON.stringify(remarks)
        };
        setIsGatepassSaving(true);
        ServerGateway
            .postGatepass(gatepass)
            .then((data) => {
                setIsGatepassSaving(false);
                toast('Your punch information saved successfully', { position: 'bottom-center', className: 'btn bg-success' });
                getGatepasses();
            })
            .catch(error => {
                console.log(error);
                setIsGatepassSaving(false);
            });
    }

    return (
        <div className="page-mark-attendance">
            <div className='container'>
                {isLoading &&
                    <Loader />
                }
                {!isLoading &&
                    <>
                        <div><Toaster /></div>
                        <div className='row' style={{ marginTop: "10px" }}>
                            <div className='card' style={{ padding: "10px" }}>
                                <>
                                    <h2>Your current location is</h2>
                                    {currentCoordinate?.latitude}:{currentCoordinate?.longitude}
                                </>
                            </div>
                            <div style={{ width: "100%", margin: "20px 0", padding: 0, position: 'relative' }}>
                                <>
                                    <Webcam ref={webcamRef} style={{ width: "90vw", margin: "auto", maxWidth: "450px" }} onPlay={handleVideoOnPlay} />
                                </>
                            </div>
                            {(captureVideo) &&
                                <>
                                    <div className="btn bg-primary w-100" onClick={punchAttendance}>
                                        {isGatepassSaving &&
                                            <div className="spinner-border text-light" role="status" style={{ height: "1.2rem", width: "1.2rem" }}>
                                                <span className="sr-only">Saving...</span>
                                            </div>
                                        }
                                        {!isGatepassSaving &&
                                            <>
                                                {gatepasses.length == 0 && <span>Punch In</span>}
                                                {gatepasses.length > 0 && (gatepasses.length % 2) == 0 && <span>Punch In</span>}
                                                {gatepasses.length > 0 && (gatepasses.length % 2) == 1 && <span>Punch Out</span>}
                                            </>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        <div className='row' style={{ marginTop: "10px" }}>
                            <>
                                <ul className="list-group list-group-flush">
                                    {gatepasses && gatepasses.map((g) => (
                                        <li className="list-group-item">
                                            {moment(g.issuedAt).format("hh:mm a")}
                                            <div className={g.isLogin ? 'badge bg-success' : 'badge bg-danger'} style={{ float: "right" }}>
                                                {g.isLogin ? "IN" : "OUT"}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        </div>
                    </>
                }
            </div>
        </div >
    );
}