import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Employee } from 'service/Models';
import ServerGateway from 'service/ServerGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardUser, faClock } from '@fortawesome/free-solid-svg-icons';

export const Attendance = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [employee, setEmployee] = useState<Employee>();
    const [month, setMonth] = useState<any>();
    const [attendance, setAttendance] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user.profile) {
            setEmployee(user.profile.profile);
        }
    }, [user]);

    useEffect(() => {
        if (month) {
            setIsLoading(true);
            var month_data = moment(month + "-01")
            ServerGateway
                .getAttendance(employee?.id, moment(month_data).year(), (moment(month_data).month() + 1))
                .then((data) => {
                    if (data.length > 0) {
                        setAttendance(data[0].attendances);
                    }
                    else {
                        setAttendance(undefined);
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [month]);

    useEffect(() => {
        setMonth(moment().format('YYYY-MM'));
    }, []);

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    }

    const getClassNameBasedOnStatus = (status) => {
        if (status === "Present") {
            return "badge bg-success"
        }
        else if (status === "Absent" || status === "LOP") {
            return "badge bg-danger"
        }
        else if (status === "Leave") {
            return "badge bg-warning"
        }
        else if (status === "WeeklyOff") {
            return "badge bg-primary"
        }
        return "badge bg-secondary";
    }


    return (
        <div className="page-attendance">
            <div className='container'>                
                <div className='row' style={{ marginBottom: "10px" }}>
                    <NavLink to="/attendance/punch" className='btn bg-primary'>
                        <FontAwesomeIcon icon={faClock} style={{ marginRight: 10 }} />
                        Mark Attendance
                    </NavLink>
                </div>
                <div className='row'>
                    <input type="month" className="form-control" value={month} onChange={handleMonthChange} style={{ textAlign: "center" }}></input>
                </div>
                <div className='row' style={{ marginTop: "20px" }}>
                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <>
                            {!attendance &&
                                <div>Attendance information not available</div>
                            }
                            {attendance &&
                                <div className="accordion" id="accordion">
                                    {attendance.map((a) => (
                                        <>
                                            {(moment().startOf('day').diff(a.date) > 0) &&
                                                <div className="accordion-item" key={moment(a.date).format("DDMMYYYY")}>
                                                    <h2 className="accordion-header" id={'ah-' + moment(a.date).format("DDMMYYYY")}>
                                                        <div className="accordion-button bg-dark collapsed" data-bs-toggle="collapse" data-bs-target={'#ad-' + moment(a.date).format("DDMMYYYY")} aria-expanded="false" aria-controls={'ad-' + moment(a.date).format("DDMMYYYY")}>
                                                            {moment(a.date).format("DD, dddd")}
                                                            <div className={getClassNameBasedOnStatus(a?.status)} style={{ float: "right" }}>{a?.status}</div>
                                                        </div>
                                                    </h2>
                                                    {(a?.status != "WeeklyOff") &&
                                                        <div className="accordion-collapse collapse" id={'ad-' + moment(a.date).format("DDMMYYYY")} aria-labelledby={'ah-' + moment(a.date).format("DDMMYYYY")} data-bs-parent="#accordion">
                                                            <div className="accordion-body">
                                                                <ul className="list-group list-group-flush">
                                                                    {(a?.status != "Leave") &&
                                                                        <>
                                                                            <li className="list-group-item">
                                                                                Login time
                                                                                <div className="fw-bold">
                                                                                    {moment(a.loginTime).format("hh:mm A") + " - " + moment(a.logoutTime).format("hh:mm A")}
                                                                                </div>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                Status
                                                                                <div className="fw-bold">{a?.status}</div>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                Working hour
                                                                                <div className="fw-bold">
                                                                                    {moment.utc(a.workingHours * 60 * 60 * 1000).format("HH:mm")}
                                                                                </div>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                Deduction
                                                                                <div className="fw-bold">
                                                                                    {moment.utc(a.deductionHours * 60 * 60 * 1000).format("HH:mm")}
                                                                                </div>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                OT
                                                                                <div className="fw-bold">
                                                                                    {moment.utc(a.otHours * 60 * 60 * 1000).format("HH:mm")}
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    }
                                                                    {(a?.status === "Leave") &&
                                                                        <>
                                                                            <li className="list-group-item">
                                                                                Leave Type
                                                                                <div className="fw-bold">
                                                                                    {a?.leaveType?.name}
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </>
                                    ))}
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}