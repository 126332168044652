import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { BusinessSummaryModel, Employee, Transaction } from 'service';
import ArthaGateway from 'service/ServerGateway';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import attendance from '../../assets/images/attendance.png'
import payslip from '../../assets/images/payslip.png'
import profile from '../../assets/images/profile.png'
import leaves from '../../assets/images/leave.png'

export const Dashboard = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [employee, setemployee] = useState<Employee>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();
    useEffect(() => {
        if (user.profile) {
            setemployee(user.profile.profile);
            setIsLoading(false);
        }
    }, [user]);

    return (
        <>
            <div className="page-dashboard">
                <div className="container">
                    <div className='row'>
                        <div className='page-header card' style={{ paddingBottom: "20px" }}>
                            Welcome,
                            <h2>{employee?.name}</h2>
                            <p>{employee?.employeeNumber}</p>
                        </div>
                    </div>
                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <div className='row'>
                            <div className="col-lg-3 col-md-6 col-6 mb-4">
                                <NavLink to="/attendance">
                                    <div className="card">
                                        <img className="card-img-top" src={attendance} alt="Card image cap"></img>
                                        <div className="card-body">
                                            <h5 className="card-title">Attendance</h5>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-4">
                                <NavLink to="/leave">
                                    <div className="card">
                                        <img className="card-img-top" src={leaves} alt="Card image cap"></img>
                                        <div className="card-body">
                                            <h5 className="card-title">Leaves</h5>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-4">
                                <NavLink to="/payslip">
                                    <div className="card">
                                        <img className="card-img-top" src={payslip} alt="Card image cap"></img>
                                        <div className="card-body">
                                            <h5 className="card-title">Payslips</h5>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-4">
                                <NavLink to="/profile">
                                    <div className="card">
                                        <img className="card-img-top" src={profile} alt="Card image cap"></img>
                                        <div className="card-body">
                                            <h5 className="card-title">My Profile</h5>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};