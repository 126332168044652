import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr'
import { Attendance, Dashboard, Leave, LeaveRequest, Login, Payslip, Profile, PunchAttendance } from './Pages';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.js';
import '../scss/style.scss'
import 'bootstrap'
import logo from "../assets/companylogo.png"
import { ActionCreators } from '../store/actionCreators';
import { RootState } from 'store/store';
import { BottomBar } from './Widgets';
import moment from 'moment';

function App() {
  const user = useSelector((state: RootState) => state.appdata);
  const dispatch = useDispatch();

  const checkLocalStorage = () => {
    console.log("Checking local storage");
    let user_data = localStorage.getItem("user");
    if (user_data && user_data.length > 0) {
      let login_time = localStorage.getItem("login_time");
      if (login_time && login_time.length > 0) {
        let last_login_time = moment(login_time);
        if (moment().diff(last_login_time, 'days') <= 30) {
          let user_info = JSON.parse(user_data);
          dispatch(ActionCreators.login(user_info));
        }
      }
    }
  }

  const isAuthenticated = () => {
    if (!user.isUserLoggedIn) {
      checkLocalStorage();
      if (!user.isUserLoggedIn) {
        return false;
      }
    }
    return true;
  }

  const PrivateRoute = ({ children }) => isAuthenticated() ? children : <Login />

  return (
    <div id="app">
      <Router>
        <nav className="navbar sticky-top navbar-dark bg-dark" style={{ padding: 0 }}>
          <div className="container">
            <a className="navbar-brand" href="#" style={{ width: "100%", textAlign: "center" }}>
              <img src={logo} width="175" alt="Emloyercare" style={{ margin: "auto" }} />
            </a>
          </div>
        </nav>
        <div className="container">
          <div className="row" style={{ marginTop: 10, marginBottom: 60 }}>
            <ReduxToastr
              timeOut={6000}
              newestOnTop={false}
              preventDuplicates
              position="bottom-center"
              getState={(state) => state.toastr} // This is the default
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick />
            <Routes>
              <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
              <Route path="/payslip" element={<PrivateRoute><Payslip /></PrivateRoute>} />
              <Route path="/attendance" element={<PrivateRoute><Attendance /></PrivateRoute>} />
              <Route path="/leave" element={<PrivateRoute><Leave /></PrivateRoute>} />
              <Route path="/leave/request" element={<PrivateRoute><LeaveRequest /></PrivateRoute>} />
              <Route path="/attendance/punch" element={<PrivateRoute><PunchAttendance /></PrivateRoute>} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </div>
        </div>
        {user.isUserLoggedIn &&
          <BottomBar />
        }
      </Router>
    </div>
  );
}

export default App;
