import axios from 'axios';

export const serverUrl = `${process.env.REACT_APP_SERVER_URL || ''}`;

axios.defaults.baseURL = serverUrl;
axios.defaults.headers.post['Content-Type'] ='application/json';

function setCompanyCodeHeader() {
    let companyCode = localStorage.getItem("companyCode");
    console.log(companyCode);
    if(companyCode) {
        console.log("Adding the header");
        axios.defaults.headers.common['companyCode'] = companyCode;
    }
}

const ServerGateway = {
    validateLogin: async function(userName, password) {
        try {
            const companyCode = userName.substring(0, userName.indexOf("-"));
            axios.defaults.headers.post['companyCode'] = companyCode;
            localStorage.setItem("companyCode", companyCode);
            const payload = { userName: userName, password: password };
            console.log("Calling validate method. Payload = "+ JSON.stringify(payload))
            let response = await axios.post('/master/employee/validate', payload);
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::validateLogin ->"+ error);
            throw error;
        }
        return false;
    },
    getSalary: async function(employeeId) {
        try {
            setCompanyCodeHeader();
            const params = { employeeId  };
            let response = await axios.get('/master/employeeSalary/list', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getDealers ->"+ error);
            throw error;
        }
        return undefined;
    },
    getPayslip: async function(employeeId, year, month) {
        try {
            setCompanyCodeHeader();
            const params = { employeeId, year, month  };
            let response = await axios.get('/payslip', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getPayslip ->"+ error);
            throw error;
        }
        return undefined;
    },
    getAttendance: async function(employeeId, year, month) {
        try {
            setCompanyCodeHeader();
            const params = { employeeId, year, month  };
            let response = await axios.get('/employee/attendance', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getLeaveTypes: async function() {
        try {
            setCompanyCodeHeader();
            let response = await axios.get('/attendance/leaveType');
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getLeaveTypes ->"+ error);
            throw error;
        }
        return undefined;
    },
    getLeaveBalance: async function(employeeId) {
        try {
            setCompanyCodeHeader();
            const params = { employeeId  };
            let response = await axios.get('/attendance/leave/balance', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getLeaveTypes ->"+ error);
            throw error;
        }
        return undefined;
    },
    getLeaves: async function(employeeId, durationId, from, to) {
        try {
            setCompanyCodeHeader();
            const params = { employeeId, durationId, from, to  };
            let response = await axios.get('/attendance/leave', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getLeaveTypes ->"+ error);
            throw error;
        }
        return undefined;
    },
    postLeave: async function(leaveRequest) {
        try {
            setCompanyCodeHeader();
            let response = await axios.post('/attendance/leave', leaveRequest);
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getLeaveTypes ->"+ error);
            throw error;
        }
        return undefined;
    },
    getLocations: async function() {
        try {
            setCompanyCodeHeader();
            let response = await axios.get('/company/location');
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getLocations ->"+ error);
            throw error;
        }
        return undefined;
    },
    postGatepass: async function(gatepass) {
        try {
            setCompanyCodeHeader();
            let response = await axios.post('/attendance/gatepass', gatepass);
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::postGatepass ->"+ error);
            throw error;
        }
        return undefined;
    },
    getGatepass: async function(employeeId, durationId) {
        try {
            setCompanyCodeHeader();
            const params = { employeeId, durationId  };
            let response = await axios.get('/attendance/gatepass', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getGatepass ->"+ error);
            throw error;
        }
        return undefined;
    },
}

export default ServerGateway;